import { Flex, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { ColoredText } from "../ColoredText"
import { Kvs, Kv } from "../Kvs"
const { stringToCharSnaps } = require("../../charSnapConverter")
const { charSnapsWithWanted } = require("../../replay")

function Backspace({ amount }: { amount: number }) {
  return (
    <ColoredText as="span" colorScheme="purple">
      <pre>
        <Flex direction="column" lineHeight={1.15} position="relative">
          ←
          {amount !== 1 && (
            <Text
              fontSize="xs"
              fontWeight="bold"
              textAlign="center"
              position="absolute"
              bottom="-.2em"
              left="50%"
              transform="translate(-50%, 50%)"
            >
              {amount}
            </Text>
          )}
        </Flex>
      </pre>
    </ColoredText>
  )
}

/** Visualize space char with a special symbol. If no char, just use am actual space. The goal is to differentiate between typed chars and spaces for layout */
function VisualizeSpace({ char }: { char: string }) {
  if (char === " ") return <span style={{ opacity: 0.3 }}>␣</span>
  return <>{char || " "}</>
}

function Char({ snap }: { snap: any }) {
  return (
    <Flex direction="column" lineHeight={1.15} mb={3}>
      <pre>
        <ColoredText
          as="span"
          colorScheme={snap.char === snap.wantedChar ? "" : "red"}
        >
          <VisualizeSpace char={snap.char} />
        </ColoredText>
      </pre>
      <pre style={{ opacity: 0.6 }}>
        <ColoredText
          as="span"
          colorScheme={
            snap.wantedChar && !snap.char
              ? "orange"
              : snap.char === snap.wantedChar
              ? ""
              : "red"
          }
        >
          <VisualizeSpace char={snap.wantedChar} />
        </ColoredText>
      </pre>
    </Flex>
  )
}

export function ReplayStatic({
  wanted,
  actual,
}: {
  wanted: string
  actual: string
}) {
  const charSnaps = stringToCharSnaps(actual)

  const snapsWithWanted = charSnapsWithWanted(charSnaps, wanted)

  return (
    <VStack spacing={4} align="start">
      <Kvs>
        <Kv k="Actual" v={actual} />
        <Kv k="Wanted" v={wanted} />
      </Kvs>
      <Flex wrap="wrap" fontSize="2xl">
        <Char
          snap={{
            char: "Actual: ",
            wantedChar: "Wanted: ",
          }}
        />
        {snapsWithWanted.map((snap: any, i: number) =>
          snap.del ? (
            <Backspace key={snap.ts + "_" + i} amount={snap.del} />
          ) : (
            <Char key={snap.ts + "_" + i} snap={snap} />
          )
        )}
      </Flex>
    </VStack>
  )
}
