/*
Goals:
- Allow replaying typed chars and the wanted chars at any moment
- Count mistakes/typos
- Print out all typed chars + deletions with wanted chars underneath
- Replay a single word
- Convert saved `typingHistory` to a `CharSnap` array

Terms:
- CharSnap: A char but with time data and potentially other useful information
*/

function omit(obj, keys) {
  const newObj = {}
  Object.keys(obj).forEach(k => {
    if (keys.includes(k)) return
    newObj[k] = obj[k]
  })
  return newObj
}

/**
 * Add `wantedChar` to each charSnap with the desired char
 */
function charSnapsWithWanted(charSnaps, wanted) {
  if (charSnaps.length === 0) return []
  if (!wanted || wanted.length === 0) {
    throw new Error("Wanted string can't be falsy")
  }

  /** This char prevents mistakes from accumulating. With this, spaces will reset so that each new word is a fresh start */
  const SPACE = " "

  const wantedWords = wanted.split(" ")

  let output = []
  let wordsI = 0
  let wordI = 0
  for (let i = 0; i < charSnaps.length; i++) {
    const snap = charSnaps[i]
    const wantedWord = wantedWords[wordsI]
    const wantedChar = wantedWord[wordI] || ""

    // eslint-disable-next-line no-loop-func
    const addUnTypedWantedChars = wordI => {
      if (wordI <= wantedWord.length - 1) {
        wantedWord
          .split("")
          .slice(wordI)
          .forEach(wantedChar => {
            output.push({
              wantedChar,
              wantedWordsIndex: wordsI,
              wantedWordIndex: wordI,
            })
            wordI++
          })
      }
    }

    if (snap.del) {
      output.push(snap)
      const filteredOutput = output.filter(snap => snap.char)

      const delAcc = output.reduce(
        (acc, curr) => (curr.del ? acc + curr.del : acc),
        0
      )

      // If deleting too much, then we'll need to set `nextSnap` to the first item
      const nextSnap =
        filteredOutput[filteredOutput.length - delAcc] || filteredOutput[0]
      wordsI = nextSnap.wantedWordsIndex
      wordI = nextSnap.wantedWordIndex
    } else if (snap.char === SPACE) {
      // If pressing spacing too early, add `wantedChar` items to output
      addUnTypedWantedChars(wordI)
      // What's "wanted" doesn't really make sense here, but oh well, setting `wantedChar: " "` anyways
      output.push({
        ...snap,
        wantedChar: SPACE,
        wantedWordsIndex: wordsI,
        wantedWordIndex: wordI,
      })
      wordsI++
      wordI = 0
    } else {
      output.push({
        ...snap,
        wantedChar,
        wantedWordsIndex: wordsI,
        wantedWordIndex: wordI,
      })
      wordI++

      // Add any untyped wanted chars at the end
      if (i === charSnaps.length - 1) {
        addUnTypedWantedChars(wordI)
      }
    }
  }

  return output.map(snap => omit(snap, ["wantedWordsIndex", "wantedWordIndex"]))
}

module.exports = {
  omit,
  charSnapsWithWanted,
}
