/** @jsx jsx */
import { Button, Icon, Spacer, VStack } from "@chakra-ui/react"
import { css, jsx } from "@emotion/react"
import { Link as GatsbyLink } from "gatsby"
import { FC } from "react"
import { RiArrowLeftLine } from "react-icons/ri"
import { BodyWrapper } from "../layout/BodyWrapper"
import { Logo } from "../layout/Logo"
import { PageHeading } from "../layout/PageHeading"
import { ToggleDarkModeSwitch } from "../ToggleDarkModeSwitch"
import { PageTitle } from "../typing-test/PageTitle"
import { Md, MdBox, MdColumn } from "../vlayout/MasterDetail"
import { DebugContent } from "./Debug"
import { DebugOnly, useDebug } from "./DebugContext"

export const Layout: FC = ({ children }) => {
  return (
    <div
      css={css`
        height: 100vh;
        display: flex;
        flex-direction: column;
      `}
    >
      {children}
    </div>
  )
}

function LogoLink() {
  const { setShowDebug } = useDebug()
  return (
    // eslint-disable-next-line jsx-a11y/accessible-emoji
    <Button
      leftIcon={<Icon as={RiArrowLeftLine} />}
      as={GatsbyLink}
      to="/"
      style={{ display: "flex" }}
      onClick={() => setShowDebug(false)}
    >
      <Logo />
    </Button>
  )
}

export const DebugLayout: FC<{
  title?: string
  dontScroll?: boolean
  flush?: boolean
}> = ({ children, title, dontScroll, flush }) => {
  return (
    <Layout>
      <PageTitle>{title}</PageTitle>
      <Md>
        <div
          style={{ flexGrow: 1, overflow: dontScroll ? undefined : "scroll" }}
        >
          {flush ? (
            children
          ) : (
            <BodyWrapper>
              {title && <PageHeading>{title}</PageHeading>}
              <VStack spacing={4} align="stretch">
                {children}
              </VStack>
            </BodyWrapper>
          )}
        </div>
        <DebugOnly>
          <MdColumn>
            <MdBox display="flex">
              <LogoLink />
              <Spacer />
              <ToggleDarkModeSwitch />
            </MdBox>
            <MdBox>
              <DebugContent />
            </MdBox>
          </MdColumn>
        </DebugOnly>
      </Md>
    </Layout>
  )
}
