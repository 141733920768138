/*
Terms:
- CharSnap: A char but with time data and potentially other useful information
*/

function charSnapsToString(charSnaps) {
  let output = ""

  charSnaps.forEach(snap => {
    if (snap.char) {
      output += snap.char
    } else if (snap.del) {
      output += snap.del === 1 ? "←" : `(←${snap.del})`
    }
  })

  return output
}

/** Don't show deletions, only print out results as if they were typed correctly from the beginning */
function charSnapsToStringCompressed(charSnaps) {
  let output = ""

  charSnaps.forEach((snap, i) => {
    if (snap.char) {
      output += snap.char
    } else if (snap.del) {
      output = output.slice(0, -snap.del)
    }
  })

  return output
}

/**
 * `matchStr` looks like examples: "←", "(←9)", "(←999)"
 */
function backspaceMatchToNumber(matchStr) {
  if (matchStr === "←") return 1
  else {
    // Match the match to find the number
    return parseInt(matchStr.match(/\d+/)[0])
  }
}

/** Matches backspace
 * EX:
 * - "SO(←2)some"
 * - "S←some"
 */
const MATCH_BACKSPACE_REGEX = /←|\(←\d+\)/g
function matchBackspaces(str) {
  const matches = [...str.matchAll(MATCH_BACKSPACE_REGEX)]
  return matches.map(match => {
    return {
      del: backspaceMatchToNumber(match[0]),
      index: match.index,
      matchLength: match[0].length,
    }
  })
}

function stringToCharSnaps(str) {
  // Using `i` for `ts` means it's about 1 char per millisecond
  let charSnaps = []

  const backspaceMatches = matchBackspaces(str)

  if (backspaceMatches.length === 0) {
    return str.split("").map((c, i) => ({ char: c, ts: i }))
  }

  let currBackspaceMatchIndex = 0
  for (let i = 0; i < str.length; i++) {
    const char = str[i]
    const charI = charSnaps.length
    const currBackspaceMatch = backspaceMatches[currBackspaceMatchIndex]
    if (currBackspaceMatch && i === currBackspaceMatch.index) {
      charSnaps.push({ del: currBackspaceMatch.del, ts: charI })
      currBackspaceMatchIndex++
      // Skip chars related to backspace
      i += currBackspaceMatch.matchLength - 1
    } else {
      charSnaps.push({ char, ts: charI })
    }
  }

  return charSnaps
}

function typingHistoryToCharSnaps(hist) {
  let snaps = []
  hist.forEach(word => {
    let prev = ""
    word.forEach(({ typed, ts }) => {
      if (typed.length > prev.length) {
        const char = typed[typed.length - 1]
        snaps.push({ char, ts })
      } else {
        const del = -(typed.length - prev.length)
        snaps.push({ del, ts })
      }
      prev = typed
    })
  })

  return snaps
}

module.exports = {
  MATCH_BACKSPACE_REGEX,
  backspaceMatchToNumber,
  matchBackspaces,
  charSnapsToString,
  charSnapsToStringCompressed,
  stringToCharSnaps,
  typingHistoryToCharSnaps,
}
