import { Box, Text } from "@chakra-ui/react"
import React, { FC, ReactNode } from "react"
import { Json } from "./Json"

export const Kvs: FC = ({ children }) => (
  <Box display="grid" gridTemplateColumns="auto auto" gridColumnGap="1em">
    {children}
  </Box>
)

export function Kv({ k, v = "–" }: { k: ReactNode; v?: any }) {
  return (
    <>
      <Box as="dt" fontWeight="bold">
        {k}:
      </Box>
      <dd>{typeof v === "object" ? <Json isAdvanced>{v}</Json> : v}</dd>
    </>
  )
}
