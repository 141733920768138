import {
  Box,
  BoxProps,
  Divider,
  HStack,
  StackDivider,
  StackProps,
  VStack,
} from "@chakra-ui/react"
import { css } from "@emotion/react"
import React, { FC } from "react"

export const Md: FC<StackProps & { showDivider?: boolean }> = ({
  children,
  showDivider,
  ...rest
}) => (
  <HStack
    css={css`
      flex-grow: 1;
      overflow: hidden;
    `}
    height="100%"
    spacing={0}
    divider={
      showDivider ? (
        <Divider orientation="vertical" height="auto" flexShrink={0} />
      ) : undefined
    }
    align="stretch"
    {...rest}
  >
    {children}
  </HStack>
)

export const MdBox: FC<BoxProps & { scroll?: boolean }> = ({
  children,
  scroll,
  ...rest
}) => {
  return (
    <Box
      px={4}
      py={2}
      {...{ overflow: scroll ? "scroll" : undefined }}
      {...rest}
    >
      {children}
    </Box>
  )
}

export const MdColumnOld: FC<BoxProps & { showDivider?: boolean }> = ({
  children,
  showDivider,
  ...rest
}) => {
  return (
    <Box width="sm" flexShrink={0} overflow="scroll" height="100%" {...rest}>
      <VStack
        spacing={0}
        align="stretch"
        divider={showDivider ? <StackDivider /> : undefined}
        height="100%"
      >
        {children}
      </VStack>
    </Box>
  )
}

export const MdColumn: FC<BoxProps & { showDivider?: boolean }> = ({
  children,
  showDivider,
  ...rest
}) => {
  return (
    <Box width="sm" flexShrink={0} overflow="scroll" height="100%" {...rest}>
      {children}
    </Box>
  )
}
