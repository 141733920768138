import React from "react"
import { DebugLayout } from "../../components/debug/DebugLayout"
import { ReplayStatic } from "../../components/typing-test/ReplayStatic"

export default function ReplayPage() {
  const examples = [
    // { wanted: "will farm follow we", actual: "sdf sdf(←3)sdf(←3)(←4)s" },
    { wanted: "will farm follow we", actual: "sd sdf(←3)sdf(←3)(←4)s" },
    { wanted: "abcdef x", actual: "abc x(←5)a" },
    // {
    //   wanted:
    //     "The quick brown fox jumped over the lazy dog. THE QUICK BROWN FOX JUMPED OVER THE LAZY DOG",
    //   actual:
    //     "T←the quick brw forx jumped over the lazy dog. THE QUICK BROWN FOX JUMPED OVER THE LAZY D",
    // },
    { wanted: "ab", actual: "XY←←ab" },
  ]

  return (
    <DebugLayout title="Replay Static">
      {examples.map((example, i) => (
        <ReplayStatic key={i} wanted={example.wanted} actual={example.actual} />
      ))}
    </DebugLayout>
  )
}
